/* eslint-disable import/no-extraneous-dependencies */
import {
  useState, useRef, useContext, useEffect,
} from 'react';
import { ApiService } from '../../services';
import {
  ContractCompleteHookState,
  initialContract,
  SignedContract,
} from './ContractComplete.types';
import { AppContext } from '../../context/appContext';
import {
  ContractPdf,
  Pdf,
  ContractPdfResponse,
} from '../../models/contractPdf';
import PostService from '../../services/PostService';
import { useAnalytics } from '../../analytics';
import PersistenceService from '../../services/storage/PersistenceService';

const useContractComplete = (): {
  state: ContractCompleteHookState;
  onDownload: (contract: SignedContract) => void;
  onContinue: () => void;
  onCloseDownloadErrorModal: () => void;
} => {
  const analytics = useAnalytics();
  const appContext = useContext(AppContext);
  const [state, setState] = useState<ContractCompleteHookState>(
    initialContract,
  );
  const PostApiRef = useRef<PostService>(new PostService());
  const apiServiceRef = useRef<ApiService>(new ApiService());

  /**
   * Purpose:
   * When the user clicks on the download Contract link the postContractPdf api will be called
   * Returns:
   * the URL of pdf then downloadPdf api will be called to download the PDF
   */

  const download = async (
    contract: SignedContract,
    applicationId: string,
    applicantId: string,
  ) => {
    analytics?.trackDownloadRequested();
    
    try {
      let downloadUrl: string;

      if (contract.contractProvider === 'html') {
        const token = PersistenceService.getValue('idToken');
        let relativeUrl = '/download?contractId=' + contract.contractId;
        if (token) {
          relativeUrl += '&token=' + token;
        }
        const url = new URL(relativeUrl, document.location.origin);
        downloadUrl = url.href;
      } else {
        const contractPdf: ContractPdf = {
          contractId: contract.contractId,
          applicationId,
          applicantId,
          isClickwrap: contract.isClickwrap,
        };

        const response: ContractPdfResponse = await apiServiceRef.current.postContractPdf(
          contractPdf,
        );

        downloadUrl = response.documentUrl;
      }

      const postMsgPayload = {
        status: 'download-requested',
        url: downloadUrl,
      };
      PostApiRef.current.postToParent(JSON.stringify(postMsgPayload));

      const pdf: Pdf = {
        url: downloadUrl,
        fileName: `${contract.contractType}.pdf`,
      };
      const fileResponse = await apiServiceRef.current.downloadPdf(pdf);
      const url = window.URL.createObjectURL(fileResponse);

      const element = document.createElement('a');
      element.href = url;
      element.download = pdf.fileName;
      element.click();
      analytics?.trackDownloadStarted();
    } catch (e) {
      analytics?.trackDownloadFailed();
      setState((pre) => ({ ...pre, showDownloadErrorModal: true }));
    }
  };

  /**
   * Purpose:
   * This function collects the required parameters to download the contract
   * Arguments:
   *  Contract Id
   * Returns:
   * N/A
   */
  const downloadContract = (contract: SignedContract) => {
    const payload = {
      trackingEventName: 'Button Click',
      trackingLabel: 'download-contract',
    };
    const data = JSON.stringify(payload);
    PostApiRef.current.postToParent(data);
    download(
      contract,
      appContext.applicationContract.applicationId,
      appContext.applicationContract.applicantId,
    );
  };

  const ContinuePage = () => {
    const trackingPayload = {
      trackingEventName: 'Button Click',
      trackingLabel: 'complete-contract',
    };
    const trackingData = JSON.stringify(trackingPayload);
    PostApiRef.current.postToParent(trackingData);

    const payload = {
      status: 'ContractComplete',
    };
    const data = JSON.stringify(payload);
    PostApiRef.current.postToParent(data);
  };

  const closeDownloadErrorModal = () => {
    setState((pre) => ({
      ...pre,
      showDownloadErrorModal: false,
    }));
  };

  /**
   * Purpose:
   * This will send container height to parent when using with iFrame
   * Arguments:
   *  Contract Id
   * Returns:
   * N/A
   */
  useEffect(() => {
    const container = document.getElementById('container');
    
    PostApiRef.current.postToParent(
      JSON.stringify({ containerHeight: container.scrollHeight }),
    );

    PostApiRef.current.postToParent(
      JSON.stringify({ status: 'SigningComplete' }),
    );
  });
  return {
    state,
    onDownload: downloadContract,
    onContinue: ContinuePage,
    onCloseDownloadErrorModal: closeDownloadErrorModal,
  };
};

export default useContractComplete;
