// extracted by mini-css-extract-plugin
export default {"htmlTemplateContainer":"Z9XmK4teAkeD0o2xZukk","contractHeader":"iFzsp8vqvFHyg87s0u94","contractHeaderLinks":"ZIXHsfs55eyVp2S1Sxrp","contractHeaderLeft":"hWaDsF_EtUcUIMEaHAwv","contractHeaderRight":"L_NFyI7jOW0Tcpf_18AO","contractAcceptance":"vgWofjrogYaLqA_5whTO","contractBody":"NYrwD5yukcpjkXoC568B"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"htmlTemplateContainer\":\"Z9XmK4teAkeD0o2xZukk\",\"contractHeader\":\"iFzsp8vqvFHyg87s0u94\",\"contractHeaderLinks\":\"ZIXHsfs55eyVp2S1Sxrp\",\"contractHeaderLeft\":\"hWaDsF_EtUcUIMEaHAwv\",\"contractHeaderRight\":\"L_NFyI7jOW0Tcpf_18AO\",\"contractAcceptance\":\"vgWofjrogYaLqA_5whTO\",\"contractBody\":\"NYrwD5yukcpjkXoC568B\"}";
        // 1737550111781
        var cssReload = require("../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  