/* eslint-disable prefer-destructuring */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, FC, useEffect, useRef, useContext } from 'react';
import {
  Button,
  Text,
  Heading,
  AlertCard,
  Link,
  ToolTip,
  AppearsFromValues,
  AlertTypeValues,
} from 'liberis-component-library';
import { capitalize, appendPageTitle } from '../../utils/AppUtil';
import { ContractFooter } from '../../models/ContractFooter';
import style from './ContractSign.scss';
import { ContractSignProps, SignPageState } from './ContractSign.types';
import useLocale from '../../hooks/useLocale';
import { ContractDocumentType } from '../../models/contract';
import PostService from '../../services/PostService';
import ContractRenderer from './ContractRenderer';
import { AppContext } from '../../context/appContext';
import { VAGARO_CAN, VAGARO_GBR, VAGARO_USA } from '../../utils/constants';
import { useAnalytics } from '../../analytics';

const containerId = 'container';

const ContractSign: FC<ContractSignProps> = ({
  title,
  subTitle,
  footerAlerts,
  contract,
  mode = SignPageState.Loading,
  documentType,
  onSignClick,
  onSetClickwrapParameters,
  onSaveLaterClick,
  onContractViewed,
}): React.JSX.Element => {
  const [scrollComplete, setScrollComplete] = useState<boolean>(false);
  const PostApiRef = useRef<PostService>(new PostService());
  const { getLocale } = useLocale();
  const [lastHeightRelatedEvent, setLastHeightRelatedEvent] = useState<string>('');
  const [lastReportedHeight, setLastReportedHeight] = useState(0);
  const [heightReportLimit, setHeightReportLimit] = useState(50);
  const context = useContext(AppContext);
  const analytics = useAnalytics();

  const onValid = (complete: boolean) => {
    analytics?.trackCheckboxClick(complete, 'Agree');
    setScrollComplete(complete);
  };

  const onInvalid = () => {
    analytics?.trackCheckboxClick(false, 'Agree');
    setScrollComplete(false);
  };

  const handleSaveLaterClick = (e: React.FormEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    onSaveLaterClick();
    return false;
  };
  const signButtonText = () => {
    const partnerCode = context.partnerCode;
    if (partnerCode === VAGARO_GBR || partnerCode === VAGARO_CAN || partnerCode === VAGARO_USA) {
      return getLocale('SignAndSubmit');
    }
    return documentType === ContractDocumentType.personalGuarantee 
      ? getLocale('SignGuarantee') 
      : getLocale('ContractSign');
  };

  const handleSignClick = () => {
    analytics?.trackSignClick();
    // Make sure we return to the top of the page in case a second contract is shown
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    // In an iFrame we can't guarantee the iFrame host will scroll correctly so we tell
    // them to shrink the iframe size to the height necessary for the loader then
    // once the new contract is in the iframe will resize with the page positioning remaining
    // at the top of the iframe.
    PostApiRef.current.postToParent(JSON.stringify({ containerHeight: 300 }));
    if (onSignClick) {
      onSignClick();
    }
  };

  const signButton = () => (
    <Button disabled={!scrollComplete || mode === SignPageState.Loading} className='SignButton'
            onClick={handleSignClick}>
      {signButtonText()}
    </Button>
  );
  const tooltipProps = {
    appearsFrom: AppearsFromValues.Top,
    text: signButton(),
  };

  useEffect(() => {
    if (scrollComplete) {
      const payload = {
        trackingEventName: 'Contract Scroll',
        trackingLabel: 'scroll-complete',
      };
      const data = JSON.stringify(payload);
      PostApiRef.current.postToParent(data);
    }
  }, [scrollComplete]);

  useEffect(() => {
    let timeout: number;

    setHeightReportLimit(15);
    const updateContainerHeight = () => {
      timeout = 0;
      const container = document.getElementById(containerId);
      const newHeight = container.scrollHeight + 20;
      const heightDifference = Math.abs(newHeight - lastReportedHeight);
  
      console.log(`height report: last(${lastReportedHeight}), scroll(${container.scrollHeight}), adjusted(${newHeight}), diff(${heightDifference}), limit(${heightReportLimit})`);
      if (heightDifference >= 1 && heightReportLimit > 0) {
        setLastReportedHeight(newHeight);
        setHeightReportLimit(limit => limit - 1);
        PostApiRef.current.postToParent(JSON.stringify({ containerHeight: newHeight }));
        analytics?.trackContractDisplayed();
      } else if (heightReportLimit == 0) {
        console.error('height report limit hit');
      }
    };

    timeout = setTimeout(updateContainerHeight, 250, 15);

    return () => {
      if (timeout != 0) {
        console.log('clearing height reporting timeout');
        clearTimeout(timeout);
      }
    };
  }, [lastReportedHeight, contract, lastHeightRelatedEvent, scrollComplete]);

  const loadAlertCard = (predicate: (val: ContractFooter) => boolean) =>
    footerAlerts.filter(predicate).map((item, idx) => {
      const element: string = capitalize(item.type);
      const type = AlertTypeValues[element as keyof typeof AlertTypeValues];

      return (
        // eslint-disable-next-line react/no-array-index-key
        <div data-dd-privacy='mask' key={`${item.type}${idx}`}>
          <AlertCard
            type={type}
            heading={item.title}
            className={style.alertNote}>
            {item.content}
          </AlertCard>
        </div>
      );
    });

  useEffect(() => {
    appendPageTitle(`Sign ${title}`);
  }, []);

  return (
    <main id={containerId} className={style.container}>
      <div className={style.content}>
        <Heading heading='h2'>{title}</Heading>
        <Text className={style.subTitle}>{subTitle}</Text>
        {contract.provider !== 'html' &&
          <div className={style.saveForLater} data-testid='saveForLater'>
            <Link className={style.saveLaterLink} href='/' onClick={handleSaveLaterClick}>
              {getLocale('SaveForLater')}
            </Link>
          </div>
        }
        <ContractRenderer
          contract={contract}
          onInvalidHandler={onInvalid}
          onValidHandler={onValid}
          onSetHandler={onSetClickwrapParameters}
          onEventAffectingHeight={setLastHeightRelatedEvent}
          onContractViewed={onContractViewed}
          onSaveLaterClick={onSaveLaterClick}
        />
        {scrollComplete && loadAlertCard((item) => item.type === 'alert')}
        {loadAlertCard((item) => item.type === 'basic')}
        <div className={style.signButton} data-testid='signButton'>
          {scrollComplete === false ? (
            <ToolTip {...tooltipProps}>{getLocale('SignButtonToolTip')}</ToolTip>
          ) : (
            signButton()
          )}
        </div>
      </div>
    </main>
  );
};

export default ContractSign;
